import React from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { SEO } from 'components'

function Products() {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    naturalYogurtXs,
    pineappleYogurtXs,
    strawberryYogurtXs,
    naturalYogurtXl,
    pineappleYogurtXl,
    strawberryYogurtXl,
    milkXl
  } = useStaticQuery(graphql`
    query {
      naturalYogurtXs: file(relativePath: { eq: "naturalYogurtXs.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pineappleYogurtXs: file(relativePath: { eq: "pineappleYogurtXs.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      strawberryYogurtXs: file(relativePath: { eq: "strawberryYogurtXs.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      naturalYogurtXl: file(relativePath: { eq: "naturalYogurtXl.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pineappleYogurtXl: file(relativePath: { eq: "pineappleYogurtXl.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      strawberryYogurtXl: file(relativePath: { eq: "strawberryYogurtXl.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      milkXl: file(relativePath: { eq: "milkXl.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const products = [
    {
      id: 1,
      img: naturalYogurtXs.childImageSharp.fluid,
      name: 'naturalYogurtXs'
    },
    {
      id: 2,
      img: strawberryYogurtXs.childImageSharp.fluid,
      name: 'strawberryYogurtXs'
    },
    {
      id: 3,
      img: pineappleYogurtXs.childImageSharp.fluid,
      name: 'pineappleYogurtXs'
    },
    {
      id: 4,
      img: naturalYogurtXl.childImageSharp.fluid,
      name: 'naturalYogurtXl'
    },
    {
      id: 5,
      img: strawberryYogurtXl.childImageSharp.fluid,
      name: 'strawberryYogurtXl'
    },
    {
      id: 6,
      img: pineappleYogurtXl.childImageSharp.fluid,
      name: 'pineappleYogurtXl'
    },
    {
      id: 7,
      img: milkXl.childImageSharp.fluid,
      name: 'milkXl'
    }
  ]

  return (
    <>
      <SEO title={t('products')} />
      <Box pt={5}>
        <Grid container alignItems='center'>
          {products.map(product => {
            return (
              <Grid item xs={12} md={4} key={product.id}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  py={5}
                  flexDirection='column'
                >
                  <Box className={classes.productImgContainer}>
                    <Img fluid={product.img} alt='Product image' />
                  </Box>
                  <Typography
                    className={classes.productName}
                    variant='h6'
                    color='primary'
                    align='center'
                  >
                    {t(product.name).toUpperCase()}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  productImgContainer: {
    width: 120,
    marginBottom: theme.spacing(3)
  },
  productName: {
    fontWeight: 'bold'
  }
}))

export default Products
